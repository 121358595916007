import * as React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout"

const PortfolioPage = () => {
  return (
    <Layout>
        <SEO title="Clients" description="A catalog of the websites I have built for clients."  />
        <main className="container">
          <title>Clients</title>
          <div className="d-flex flex-wrap justify-content-center">
            <div className="portfolioItem d-flex flex-column">
                <a href="https://tnsdetailing.com" target="_blank" className="d-flex flex-wrap justify-content-center text-decoration-none text-dark">
                    <video poster={'/images/tns-detailing-poster.jpg'} autoPlay playsInline muted loop className="portfolioMedia">
                        <source src={'/images/tns-detailing.mp4'} type="video/mp4"></source>
                    </video>
                    <span className="fs-5 text-center">
                        TNS Detailing &amp; Restoration
                    </span>
                </a>
            </div>
            <div className="portfolioItem d-flex flex-column">
                <a href="https://www.divernonsportsmansclub.com/" target="_blank" className="d-flex flex-wrap justify-content-center text-decoration-none text-dark">
                    <img src={'/images/divernon.PNG'} className="portfolioMedia"></img>
                    <span className="fs-5 text-center">
                        Divernon Sportsmans Club
                    </span>
                </a>
            </div>
            <div className="portfolioItem d-flex flex-column">
                <a href="https://springfieldzoosociety.org" target="_blank" className="d-flex flex-wrap justify-content-center text-decoration-none text-dark">
                    <img src={'/images/zoo-society.PNG'} className="portfolioMedia"></img>
                    <span className="fs-5 text-center">
                        Springfield Zoological Society
                    </span>
                </a>
            </div>
            <div className="portfolioItem d-flex flex-column">
                <a href="https://pap-society.org" target="_blank" className="d-flex flex-wrap justify-content-center text-decoration-none text-dark">
                    <img src={'/images/pap-society.PNG'} className="portfolioMedia"></img>
                    <span className="fs-5 text-center">
                        Pakistani-American Pathologists' Society
                    </span>
                </a>
            </div>
            <div className="portfolioItem d-flex flex-column">
                <a href="https://www.icarecentral.com/" target="_blank" className="d-flex flex-wrap justify-content-center text-decoration-none text-dark">
                    <img src={'/images/icare.PNG'} className="portfolioMedia"></img>
                    <span className="fs-5 text-center">
                        iCare - Cleaning and Home Services
                    </span>
                </a>
            </div>
          </div>
        </main>
    </Layout>
  )
}
  
export default PortfolioPage